/**
 * Smooth Link
 */

class SmoothLink {
  constructor(selector) {
    this.selector = selector ? document.querySelectorAll(selector) : false;
    this.selector.length ? this.initLinks(this.selector) : false;
  }

  initLinks(links) {
    const that = this;
    links.forEach((link) => {
      const linkURL = link.getAttribute("href");
      const hash = linkURL ? linkURL.split("#")[1] : false;

      if (linkURL && !linkURL.includes("#modal") && hash) {
        link.onclick = (e) => {
          e.preventDefault();
          that.doTheWindowScroll(link, hash);
        };
      }
    });
  }

  doTheWindowScroll(link, hash) {
    if (link && hash) {
      setTimeout(() => {
        const siteHeaderHeight = document
          .querySelector(".site-header")
          .getBoundingClientRect().height;
        const wpAdminBarHeight = document.querySelector("#wpadminbar")
          ? document.querySelector("#wpadminbar").getBoundingClientRect().height
          : 0;
        const hashBlockTopOffset = document.querySelector("#" + hash)
          ? document.querySelector("#" + hash).getBoundingClientRect().top -
            (siteHeaderHeight + wpAdminBarHeight)
          : 0;

        setTimeout(() => {
          hashBlockTopOffset
            ? window.scrollTo({
                top: hashBlockTopOffset + window.scrollY,
                behavior: "smooth",
              })
            : false;
        }, 40);
      }, 60);
    }
  }
}

export default new SmoothLink("a");
