import "./Menu.js";
import "./Header.js";
import "./Modal.js";
import "./Searchbar.js";
import "./SmoothLink.js";
import "./FaqLoader.js";
import "./animations.js";
import "./hubspotNewsletter.js";

// Cookiebar
import "./Cookiebar.js";

// Load slider as soon as the DOM is loaded
import Slider from "./sliders/Slider.js";
document.addEventListener("DOMContentLoaded", () => {
  new Slider(document.querySelectorAll(".slider"));
});
