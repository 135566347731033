/**
 * FAQLoader
 * ----------------------------------------------------------------
 * This class handles the loadmore functionality of the faq list and faq item.
 */

import { slideDown, slideUp } from "slide-animator";

class LoadMore {
  constructor() {
    this.LoadMoreSection = document.querySelector(".js-load-more");

    if (this.LoadMoreSection) {
      this.initFaqToggle();
      this.initLoadMore();
    }
  }

  /**
   * Trigger foldout by click
   */
  initFaqToggle() {
    document.addEventListener("click", (event) => {
      const faqTextToggler = event.target.closest(".js-faq-text-toggler");

      if (faqTextToggler) {
        event.preventDefault();
        const faqItemText = faqTextToggler.nextElementSibling;
        faqTextToggler.classList.toggle("active");
        if (faqItemText.style.display === "none") {
          slideDown(faqItemText, 200);
          faqTextToggler.parentElement.setAttribute("open", true);
        } else {
          slideUp(faqItemText);
          setTimeout(() => {
            faqTextToggler.parentElement.removeAttribute("open");
          }, 300);
        }
      }
    });
  }

  /**
   * Ajax load more.
   */
  initLoadMore() {
    document.addEventListener("click", (event) => {
      const loadMoreButton = event.target.closest(".js-load-more");
      if (loadMoreButton) {
        event.preventDefault();
        const me = loadMoreButton;

        me.classList.add("button--loading");

        const fetchHeaderData = new FormData();
        fetchHeaderData.append("action", "load_more");
        fetchHeaderData.append("posttype", me.getAttribute("data-posttype"));
        fetchHeaderData.append(
          "no_posts",
          parseInt(me.getAttribute("data-posts"))
        );
        fetchHeaderData.append(
          "no_posts_loaded",
          parseInt(me.getAttribute("data-postsloaded"))
        );

        me.setAttribute("disabled", true);
        me.classList.add("button--loading");
        me.textContent = "Loading...";

        fetch(ajaxurl, {
          method: "POST",
          body: fetchHeaderData,
        })
          .then((response) => response.text())
          .then((response) => {
            console.log(response);
            document.querySelector(".faq-block__content").innerHTML = response;

            // update loadmore button data-attr for post offset in next load
            const new_offset =
              parseInt(me.getAttribute("data-posts")) +
              parseInt(me.getAttribute("data-postsloaded"));
            me.setAttribute("data-postsloaded", new_offset);

            // Article counter
            const articles_counter = document.querySelector(
              ".js-articles-counter"
            ).textContent;

            // Hide load more button if no more posts
            if (new_offset >= parseInt(articles_counter)) {
              me.classList.add("button--hide");
            } else {
              me.classList.remove("button--hide");
            }

            me.setAttribute("disabled", false);
            me.classList.remove("button--loading");
            me.textContent = "Show more";
          })
          .catch((error) => {
            console.error("Error:", error);
            me.setAttribute("disabled", false);
            me.classList.remove("button--loading");
            me.textContent = "Show more";
          });
      }
    });
  }
}

new LoadMore();
