import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

if (document.querySelector(".js-animate-fadein")) {
  const fadeins = gsap.utils.toArray(".js-animate-fadein");
  gsap.set(fadeins, { opacity: 0 });
  ScrollTrigger.batch(fadeins, {
    onEnter: (batch) =>
      gsap.to(batch, { opacity: 1, stagger: 0.1, duration: 0.9 }),
  });
}

if (document.querySelector(".js-animate-fadeinup")) {
  const fadeinups = gsap.utils.toArray(".js-animate-fadeinup");
  gsap.set(fadeinups, { y: 10, opacity: 0 });
  ScrollTrigger.batch(fadeinups, {
    onEnter: (batch) =>
      gsap.to(batch, { opacity: 1, y: 0, stagger: 0.3, duration: 0.4 }),
  });
}

if (document.querySelector(".js-animate-up")) {
  const fadeinups = gsap.utils.toArray(".js-animate-up");
  gsap.set(fadeinups, { y: 30 });
  ScrollTrigger.batch(fadeinups, {
    onEnter: (batch) => gsap.to(batch, { y: 0, stagger: 0.1, duration: 0.9 }),
  });
}

// Hero block animations
// const heroContent = document.querySelector(".js-hero-parallax-content");
const heroMedia = document.querySelector(".js-hero-media");
const heroDecor = document.querySelector(".js-hero-decor");
const blockDecorElements = document.querySelectorAll(".js-animate-decor");

// Parallax effect
if (heroDecor) {
  window.addEventListener("scroll", () => {
    const scrollY = window.scrollY;

    heroDecor.style.transform = `translateY(-${scrollY * 0.08}%)`;

    if (heroMedia && scrollY <= 300) {
      console.log("ANIMTING SCROLL");
      heroMedia.parentElement.style.transform = `translateY(${
        scrollY * 0.05
      }%)`;
    }
  });
}

// Animate block decor based on the scroll
if (blockDecorElements) {
  blockDecorElements.forEach((decorElement) => {
    gsap.to(decorElement, {
      "--animation-y": () => {
        const translateY =
          decorElement.getBoundingClientRect().height - window.scrollY;
        return translateY * 0.6 + "px";
      },
      ease: "none",
      duration: 2,
      scrollTrigger: {
        trigger: decorElement,
        start: "top bottom",
        end: "bottom top",
        scrub: 1,
      },
    });
  });
}
