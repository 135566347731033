/**
 * Searchbar.js
 * Handles all the flipflop animation and class exchanges on Header search bar
 */

class SearchBar {
  constructor() {
    this.searchBarSwitch = document.querySelector(".js-toggle-searchbar");
    this.searchBarSwitchLink = this.searchBarSwitch
      ? document.querySelector(".js-toggle-searchbar--link")
      : false;
    this.searchBar = document.querySelector(".js-search-popup");

    // Prevent Link
    const searchBarInput = document.getElementById("site-search-field");
    if (this.searchBar && this.searchBarSwitch && searchBarInput) {
      this.preventSearchSwitchLink();

      // Focus the input when the searchbarlink is hovered
      this.searchBarSwitch.addEventListener("mouseover", () => {
        searchBarInput.focus();
      });
    }

    if (this.searchBarSwitchLink) {
      this.searchBarSwitchLink.addEventListener("mouseover", {});
    }
  }

  searchBarSwitchEventHandler() {
    if (
      document
        .querySelector("body")
        .classList.contains("js-search-form--active")
    ) {
      document.querySelector("body").classList.remove("js-search-form--active");
    } else {
      document.querySelector("body").classList.add("js-search-form--active");
    }
  }

  preventSearchSwitchLink() {
    this.searchBarSwitchLink.addEventListener("focusin", (e) => {
      this.searchBarSwitch.classList.add("js-submenu--active");
      document.querySelector("body").classList.add("js-search-form--active");
      this.searchBarSwitch.focus();
    });

    this.searchBarSwitchLink.addEventListener("focusout", (e) => {
      this.searchBarSwitch.classList.remove("js-submenu--active");
      document.querySelector("body").classList.remove("js-search-form--active");
    });

    this.searchBarSwitchLink.addEventListener("click", (e) => {
      e.preventDefault();
    });
  }
}

export default new SearchBar();
