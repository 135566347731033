/**
 * Load Hubspot JS for Newsletter when the HS script is in the viewport
 * ----------------------------------------------------------------------------------------
 *  Create an Intersection Observer instance to observe the elements in the viewport
 */
const targetElement = document.querySelector(".js-hubspot-newsletter-form");

const observer = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        loadHubSpotScript();
        observer.unobserve(entry.target);
      }
    });
  },
  {
    root: null,
    rootMargin: "-20% 0%", // Load the script 20% before entering the viewport
    threshold: 0.5, // Adjust as needed
  }
);

// Start observing the target element
observer.observe(targetElement);

// Function to load HubSpot script
function loadHubSpotScript() {
  const hubSpotScript = document.createElement("script");
  hubSpotScript.src = "https://js.hsforms.net/forms/v2.js";
  hubSpotScript.async = true;
  document.body.appendChild(hubSpotScript);

  // When the HS script is loaded
  hubSpotScript.onload = () => {
    if (hbspt) {
      hbspt.forms.create({
        css: "",
        region: "na1",
        portalId: "1550036",
        target: ".js-hubspot-newsletter-form .hbspt-form",
        formId: "b67c4872-6c16-4e7a-870e-b45d9f740ad3",
      });
    }
  };
}
